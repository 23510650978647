import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import { usePageContext } from '../../i18ncontext'

interface ISeoProps {
  description?: string
  lang?: string
  meta?: any[]
  title?: string
  originalPath?: string
}

interface IPureSeoProps extends ISeoProps {
  siteMetaData: { [key: string]: any }
}

export const PureSeo: React.FC<IPureSeoProps> = ({
  description,
  lang,
  meta = [],
  title,
  siteMetaData,
  originalPath,
}) => {
  const host = siteMetaData.siteUrl

  const baseMetaData = [
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: siteMetaData.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
    {
      property: `og:locale`,
      content: lang,
    },
  ]

  const generateMetaData = (): any[] => {
    // GA target is July
    const isDevPortalGa = false
    return isDevPortalGa &&
      process.env.GATSBY_ACTIVE_ENV &&
      process.env.GATSBY_ACTIVE_ENV.includes('prod')
      ? baseMetaData
      : [...baseMetaData, { name: 'robots', content: 'noindex' }]
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defaultTitle={siteMetaData.title}
      title={title}
      titleTemplate={`%s | ${siteMetaData.title}`}
      meta={generateMetaData().concat(meta)}
      link={[
        {
          rel: 'canonical',
          href:
            lang === 'en'
              ? `${host}/${lang}${originalPath}`
              : `${host}${originalPath}`,
        },
        {
          rel: 'alternate',
          hrefLang: 'x-default',
          href: `${host}${originalPath}`,
        },
        ...siteMetaData.supportedLanguages.map((supportedLang: string) => ({
          rel: 'alternate',
          hrefLang: supportedLang,
          href: `${host}/${supportedLang}${originalPath}`,
        })),
      ]}
    />
  )
}

const Seo: React.FC<ISeoProps> = ({ description, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            supportedLanguages
            siteUrl
          }
        }
      }
    `
  )

  const { lang, originalPath } = usePageContext()

  const metaDescription = description || site.siteMetadata.description
  return (
    <PureSeo
      description={metaDescription}
      meta={meta}
      title={title}
      siteMetaData={site.siteMetadata}
      lang={lang || 'en'}
      originalPath={originalPath || ''}
    />
  )
}

export default Seo
